






































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import FormSwitch from '@/components/form/form-switch.vue';
import DatePicker from '@/components/form/date-picker.vue';
import { calculateClaimsRequirementPremium,
  copyObject,
  onCurrencyFocus,
  recursiveFillData } from '@/helper/functions';
import popUpModal from '@/components/form/pop-up-modal.vue';
import apiBackend from '@/service/api-insurance';
import html2pdf from 'html2pdf.js';
import { fillErgebnisseTarifkalkulationPramie, getVersicherungssummeAktuell } from '@/helper/priceCalculator/biogas-calculator';
import xmlStatusIcon from '@/components/form/XML-status-icon.vue';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';
// import priceStatusIcon from '@/components/form/price-status-icon.vue';

const variantItem = {
  tarifPramie: 0,
  laufzeit: 1,
  SVR: 0,
  betrachtungszeitraum: '',
  SQ: '',
  nettoisierung: 0,
  pramieInklTariflicherNachlasse: 0,
  showInPdf: true,
};

export default Vue.extend({
  name: 'price-card',
  mixins: [dataChangeMixin],
  components: {
    // priceStatusIcon,
    FormSwitch,
    DatePicker,
    popUpModal,
    xmlStatusIcon,
  },
  props: [
    'id',
    'insuranceKey',
    'insuranceData',
    'processSavingData',
    'insuranceFormsApiData',
    'priceData',
    'AMSAPISendStatus',
  ],
  data(): any {
    return {
      name: 'biogas-insurance-price',
      key: 'BIOGAS_INSURANCE',
      pdfStyles: false,
      variant: 'tarif',
      modalOpen: false,
      modalTitle: '',
      modalBody: '',
      priceStep: 'basic',
      queue: [],
      zusammenfassung: {
        versicherungsnehmer: '',
        betriebsart: '',
        versicherungssummeAktuell: 0,
        versicherungssumme371: 0,
        produkt: 'Biogas',
        nettoisiert: '',
        steuerland: 'Deutschland',
        vorversicherer: '',
        versicherungsscheinnummerVorvertrag: '',
        PML: 0,
      },
      kalkulationsergebnis: {
        hazardGroups: {
          feuer: '',
          BU: '',
          maschinenBruch: '',
          bauleistungMontage: '',
        },
      },
      ergebnisseTarifkalkulation: {
        tarif: {
          name: 'SB-Tarif SB',
          selbstbehalt: '1.000 EUR',
          ...copyObject(variantItem),
          priceKey: 'tarif',
        },
        SBVariante1: {
          name: 'SB-Variante 1',
          selbstbehalt: '',
          ...copyObject(variantItem),
          priceKey: 'variant1',
        },
        SBVariante2: {
          name: 'SB-Variante 2',
          selbstbehalt: '',
          ...copyObject(variantItem),
          priceKey: 'variant2',
        },
        SBVariante3: {
          name: 'SB-Variante 3',
          selbstbehalt: 'Misch SB-Variante',
          ...copyObject(variantItem),
          priceKey: 'variant3',
        },
      },
      vorschadenhistorie: {
        select: false,
        jahr1: {
          schaden: 0,
          reserven: 0,
        },
        jahr2: {
          schaden: 0,
          reserven: 0,
        },
        jahr3: {
          schaden: 0,
          reserven: 0,
        },
        jahr4: {
          schaden: 0,
          reserven: 0,
        },
        jahr5: {
          schaden: 0,
          reserven: 0,
        },
        summe: {
          schaden: 0,
          reserven: 0,
        },
        SBVorvertrag: 0,
        Betrachtungszeitraum: 0,
        Zielschadenquote: 0,
        MindestjahrespramieTarifVariante: '',
        MindestjahrespramieSBVariante1: '',
        MindestjahrespramieSBVariante2: '',
        MindestjahrespramieSBVariante3: '',
        ZusatzlicherVorschadenfaktor: 0,
        Begrundung: '',
      },
      skillVollmachten: {
        TariffVariant: {
          estates: 0,
          surcharges: 0,
        },
        SBvariant1: {
          estates: 0,
          surcharges: 0,
        },
        SBvariant2: {
          estates: 0,
          surcharges: 0,
        },
        SBvariant3: {
          estates: 0,
          surcharges: 0,
        },
        reason: '',
      },
      vierAugenPrinzip: {
        show: false,
        discussedWith: '',
        date: '',
      },
      promotionalRewards: {
        tarif: {
          name: 'Tarif- SB',
          selbstbehalt: '1.000 EUR',
          pramieInklTariflicher: 0,
          pramieInklVorschadenfaktor: 0,
          pramieInklSkill: 0,
          angebotspramiensatzNetto: '',
          angebotspramiensatzBrutto: '',
          sentXmlRequest: false,
        },
        SBVariante1: {
          name: 'SB-Variante 1',
          selbstbehalt: '',
          pramieInklTariflicher: 0,
          pramieInklVorschadenfaktor: 0,
          pramieInklSkill: 0,
          angebotspramiensatzNetto: 0,
          angebotspramiensatzBrutto: '',
          sentXmlRequest: false,
        },
        SBVariante2: {
          name: 'SB-Variante 2',
          selbstbehalt: '',
          pramieInklTariflicher: 0,
          pramieInklVorschadenfaktor: 0,
          pramieInklSkill: 0,
          angebotspramiensatzNetto: '',
          angebotspramiensatzBrutto: '',
          sentXmlRequest: false,
        },
        SBVariante3: {
          name: 'SB-Variante 3',
          selbstbehalt: 'Misch SB-Variante',
          pramieInklTariflicher: 0,
          pramieInklVorschadenfaktor: 0,
          pramieInklSkill: 0,
          angebotspramiensatzNetto: '',
          angebotspramiensatzBrutto: '',
          sentXmlRequest: false,
        },
      },
      promotionalRewardsCssClasses: {
        tarif: 'bg-gray',
        SBVariante1: 'table-variant-1',
        SBVariante2: 'table-variant-2',
        SBVariante3: 'table-variant-3',
      },
      ergebnisseTarifkalkulationCssClasses: {
        tarif: 'bg-gray',
        SBVariante1: 'table-variant-1',
        SBVariante2: 'table-variant-2',
        SBVariante3: 'table-variant-3',
      },
      hinweiseZumBeitrag: '',
    };
  },
  watch: {
    ergebnisseTarifkalkulation: {
      handler() {
        this.calculatePromotionalRewards();
      },
      deep: true,
    },
    skillVollmachten: {
      handler() {
        this.calculatePromotionalRewards();
      },
      deep: true,
    },
    zusatzlicherVorschadenfaktorСoefficient() {
      this.calculatePromotionalRewards();
    },
    processSavingData(value: boolean) {
      if (value) {
        this.executeQueue();
      }
    },
    'insuranceData.spreadsheetData': function () {
      if (this.insuranceData && this.insuranceData.spreadsheetData) {
        this.zusammenfassung.versicherungssummeAktuell = this.insuranceData?.spreadsheetData?.paramsVertragsdaten[0].sumInsured;
        this.zusammenfassung.versicherungssumme371 = this.insuranceData?.spreadsheetData?.paramsVertragsdaten[0].sum371;
        this.zusammenfassung.nettoisiert = this.insuranceData?.spreadsheetData?.paramsVertragsdaten[0].netization;
        fillErgebnisseTarifkalkulationPramie(this.priceData, this.insuranceData.spreadsheetData, this.ergebnisseTarifkalkulation);
        this.fillApiValuesData();
      }
    },
    priceData() {
      this.onNextClick();
    },
  },
  computed: {
    selectedBu(): boolean {
      return this.$store.getters['insuranceModule/biogasModule/getSelectedBu'];
    },
    vorschadenhistorieSummeSchaden(): number {
      return (+this.vorschadenhistorie.jahr1.schaden) +
        (+this.vorschadenhistorie.jahr2.schaden) +
        (+this.vorschadenhistorie.jahr3.schaden) +
        (+this.vorschadenhistorie.jahr4.schaden) +
        (+this.vorschadenhistorie.jahr5.schaden);
    },
    vorschadenhistorieSummeReserven(): number {
      return (+this.vorschadenhistorie.jahr1.reserven) +
        (+this.vorschadenhistorie.jahr2.reserven) +
        (+this.vorschadenhistorie.jahr3.reserven) +
        (+this.vorschadenhistorie.jahr4.reserven) +
        (+this.vorschadenhistorie.jahr5.reserven);
    },
    // Schadenbedarfsprämie
    claimsRequirementPremium(): number {
      return calculateClaimsRequirementPremium(
        this.vorschadenhistorieSummeSchaden,
        this.vorschadenhistorieSummeReserven,
        +this.vorschadenhistorie.Betrachtungszeitraum,
        +this.vorschadenhistorie.Zielschadenquote,
      );
    },
    zusatzlicherVorschadenfaktorСoefficient(): number {
      return (100 - this.vorschadenhistorie.ZusatzlicherVorschadenfaktor) / 100;
    },
    isApiUser(): boolean {
      return this.$store.getters['authModule/isApiUser'];
    },
    isPCUser(): boolean {
      return this.$store.getters['authModule/isPCUser'];
    },
  },
  methods: {
    getXmlBtnIsDisable(index: string) {
      if (this.AMSAPISendStatus !== null) {
        const status = this.AMSAPISendStatus[index];
        if (status !== null) {
          return (!status.dataUpdated && status.statusSuccess);
        }
      }
      return false;
    },
    onCurrencyFocus,
    getXMLKey(variantParam: string) {
      let variant = 'tarif';
      if (variantParam === 'SBTarif') {
        variant = 'tarif';
      } else if (variantParam === 'SBVariante1') {
        variant = 'variant1';
      } else if (variantParam === 'SBVariante2') {
        variant = 'variant2';
      } else if (variantParam === 'SBVariante3') {
        variant = 'variant3';
      }
      return variant;
    },
    /* queueGetPrice(variantPrice: string) {
      this.$emit('add-to-queue', 'getPriceUseId', variantPrice);
      this.onNextClick();
    }, */
    fillApiValuesData() {
      if (this.insuranceFormsApiData && this.insuranceFormsApiData.priceFormData) {
        recursiveFillData(this.zusammenfassung, this.insuranceFormsApiData.priceFormData.zusammenfassung);
        recursiveFillData(this.kalkulationsergebnis, this.insuranceFormsApiData.priceFormData.kalkulationsergebnis);
        recursiveFillData(this.ergebnisseTarifkalkulation, this.insuranceFormsApiData.priceFormData.ergebnisseTarifkalkulation);
        recursiveFillData(this.vorschadenhistorie, this.insuranceFormsApiData.priceFormData.vorschadenhistorie);
        recursiveFillData(this.skillVollmachten, this.insuranceFormsApiData.priceFormData.skillVollmachten);
      }
    },
    enqueue(functionName: string) {
      // Add an item to the end of the queue
      this.queue.push(functionName);
    },
    onSubmit() {
      if (this.selectedBu) {
        this.onNextClick('priceForms', 2);
      } else {
        this.onNextClick();
      }
    },
    queueDownloadPDF() {
      this.onNextClick();
      this.enqueue('downloadPDF');
    },
    queueSendBigXML(variant) {
      this.variant = variant;
      this.saveZusammenfassungPDF('sendBigXML', variant);
      // this.enqueue('sendBigXML');
    },
    queueDownloadBigXML(variant) {
      this.variant = variant;
      this.saveZusammenfassungPDF('downloadBigXML', variant);
      // this.enqueue('downloadBigXML');
    },
    executeQueue() {
      // Iterate over the queue and execute each function
      // eslint-disable-next-line no-restricted-syntax
      for (const functionName of this.queue) {
        if (typeof this[functionName] === 'function') {
          this[functionName](); // Call the function
        }
      }
      // Clear the queue after executing all functions
      this.queue = [];
    },
    sendBigXML() {
      apiBackend.sendBigXML(this.id, {
        variant: this.variant,
      }).then((response) => {
        this.$emit('get-draft-insurance', this.id);
      });
    },
    downloadBigXML() {
      apiBackend.downloadBigXML(this.id, {
        variant: this.variant,
        tab: 'price',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'big_xml.xml');
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    exportToPDF() {
      const filename = `Zusammenfassung-Windenergieanlagen-Price-${this.id}.pdf`;
      this.pdfStyles = true;
      html2pdf(document.getElementById('element-to-convert'), {
        margin: 10,
        filename,
        html2canvas: {
          width: 1500,
        },
      }).then((response) => {
        this.pdfStyles = false;
      });
    },
    saveZusammenfassungPDF(functionName: string, variant: string) {
      const element = document.getElementById('element-to-convert');
      const filename = `Zusammenfassung-Windenergieanlagen-Price-${this.id}.pdf`;
      this.pdfStyles = true;
      const opt = {
        margin: 10,
        filename,
        html2canvas: {
          width: 1500,
        },
      };
      html2pdf().set(opt).from(element).output('datauristring')
        .then((response) => {
          const arr = response.split(',');
          const pfdStr = arr[1];
          const data = {
            insuranceId: this.id,
            pfdStr,
          };
          this.promotionalRewards[variant].sentXmlRequest = true;
          apiBackend.savePDF(data).then((r) => {
            this.enqueue(functionName);
            this.onNextClick();
          });
          this.pdfStyles = false;
        });
    },
    downloadPDF() {
      setTimeout(() => {
        apiBackend.downloadPDF(this.id).then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `Vorschlag-${this.id}.pdf`);
          document.body.appendChild(fileLink);
          fileLink.click();
        });
      }, 1000);
    },
    openModal(title: string, body: string) {
      this.modalTitle = title;
      this.modalBody = body;
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalTitle = '';
      this.modalBody = '';
      this.modalOpen = false;
    },
    calculateErgebnisseTarifkalkulation(item: any) {
      const laufzeitCoeficient = (item.laufzeit === 3) ? 0.9 : 1;
      const SVRCoeficient = 1 - (item.SVR / 100);
      const nettoisierungCoeficient = 1 - (item.nettoisierung / 100);
      return Number(item.tarifPramie) * laufzeitCoeficient * SVRCoeficient * nettoisierungCoeficient;
    },
    calculatePromotionalRewards() {
      const calculate = (data, estates, surcharges) => {
        const { pramieInklTariflicherNachlasse, name, selbstbehalt } = data;
        const pramieInklVorschadenfaktor = (pramieInklTariflicherNachlasse * this.zusatzlicherVorschadenfaktorСoefficient);
        const pramieInklSkill = pramieInklVorschadenfaktor * (1 - estates * 0.01) * (1 + surcharges * 0.01);
        const angebotspramiensatzNetto = (pramieInklSkill / this.zusammenfassung.versicherungssummeAktuell) * 1000;
        const angebotspramiensatzBrutto = pramieInklSkill * 1.19;
        return {
          name,
          selbstbehalt,
          pramieInklTariflicher: parseFloat(Number(pramieInklTariflicherNachlasse).toFixed(2)),
          pramieInklVorschadenfaktor: parseFloat(Number(pramieInklVorschadenfaktor).toFixed(2)),
          pramieInklSkill: parseFloat(Number(pramieInklSkill).toFixed(2)),
          angebotspramiensatzNetto: parseFloat(Number(angebotspramiensatzNetto).toFixed(2)),
          angebotspramiensatzBrutto: parseFloat(Number(angebotspramiensatzBrutto).toFixed(2)),
        };
      };

      this.promotionalRewards.tarif = calculate(
        this.ergebnisseTarifkalkulation.tarif,
        this.skillVollmachten.TariffVariant.estates,
        this.skillVollmachten.TariffVariant.surcharges,
      );
      this.promotionalRewards.SBVariante1 = calculate(
        this.ergebnisseTarifkalkulation.SBVariante1,
        this.skillVollmachten.SBvariant1.estates,
        this.skillVollmachten.SBvariant1.surcharges,
      );
      this.promotionalRewards.SBVariante2 = calculate(
        this.ergebnisseTarifkalkulation.SBVariante2,
        this.skillVollmachten.SBvariant2.estates,
        this.skillVollmachten.SBvariant2.surcharges,
      );
      this.promotionalRewards.SBVariante3 = calculate(
        this.ergebnisseTarifkalkulation.SBVariante3,
        this.skillVollmachten.SBvariant3.estates,
        this.skillVollmachten.SBvariant3.surcharges,
      );
      this.ergebnisseTarifkalkulation.tarif.pramieInklTariflicherNachlasse = parseFloat(Number(this.calculateErgebnisseTarifkalkulation(this.ergebnisseTarifkalkulation.tarif)).toFixed(2));
      this.ergebnisseTarifkalkulation.SBVariante1.pramieInklTariflicherNachlasse = parseFloat(Number(this.calculateErgebnisseTarifkalkulation(this.ergebnisseTarifkalkulation.SBVariante1)).toFixed(2));
      this.ergebnisseTarifkalkulation.SBVariante2.pramieInklTariflicherNachlasse = parseFloat(Number(this.calculateErgebnisseTarifkalkulation(this.ergebnisseTarifkalkulation.SBVariante2)).toFixed(2));
      this.ergebnisseTarifkalkulation.SBVariante3.pramieInklTariflicherNachlasse = parseFloat(Number(this.calculateErgebnisseTarifkalkulation(this.ergebnisseTarifkalkulation.SBVariante3)).toFixed(2));
    },
    goToBackUrl() {
      if (this.selectedBu) {
        this.$emit('go-back-tab', 'biogas-insurance-risk', 'insuranceForms', 3);
      } else {
        this.$emit('go-back-tab', 'biogas-insurance-tarif', 'insuranceForms', 2);
      }
    },
    setValues(priceFormData: any) {
      this.zusammenfassung = priceFormData.zusammenfassung;
      this.kalkulationsergebnis = priceFormData.kalkulationsergebnis;
      this.ergebnisseTarifkalkulation = priceFormData.ergebnisseTarifkalkulation;
      this.vorschadenhistorie = priceFormData.vorschadenhistorie;
      this.skillVollmachten = priceFormData.skillVollmachten;
      this.vierAugenPrinzip = priceFormData.vierAugenPrinzip;
      this.vierAugenPrinzip.date = new Date(priceFormData.vierAugenPrinzip.date);
      this.promotionalRewards = priceFormData.promotionalRewards;
      this.hinweiseZumBeitrag = priceFormData.hinweiseZumBeitrag;
    },
    onNextClick(groupId = '', tabId = 0) {
      this.vorschadenhistorie.summe.schaden = this.vorschadenhistorieSummeSchaden;
      this.vorschadenhistorie.summe.reserven = this.vorschadenhistorieSummeReserven;
      this.vorschadenhistorie.MindestjahrespramieTarifVariante = this.claimsRequirementPremium;

      const data = {
        zusammenfassung: this.zusammenfassung,
        kalkulationsergebnis: this.kalkulationsergebnis,
        ergebnisseTarifkalkulation: this.ergebnisseTarifkalkulation,
        vorschadenhistorie: this.vorschadenhistorie,
        skillVollmachten: this.skillVollmachten,
        vierAugenPrinzip: this.vierAugenPrinzip,
        promotionalRewards: this.promotionalRewards,
        hinweiseZumBeitrag: this.hinweiseZumBeitrag,
      };
      this.$emit('price-formData-changed', data, this.priceStep, groupId, tabId);
    },
    fillAngebotspramien(selbstbeteiligungsvariantenEinspeisevergutung) {
      this.promotionalRewards.SBVariante1.selbstbehalt = selbstbeteiligungsvariantenEinspeisevergutung.items.SBVariante1.SB;
      this.ergebnisseTarifkalkulation.SBVariante1.selbstbehalt = selbstbeteiligungsvariantenEinspeisevergutung.items.SBVariante1.SB;
      this.promotionalRewards.SBVariante2.selbstbehalt = selbstbeteiligungsvariantenEinspeisevergutung.items.SBVariante2.SB;
      this.ergebnisseTarifkalkulation.SBVariante2.selbstbehalt = selbstbeteiligungsvariantenEinspeisevergutung.items.SBVariante2.SB;
    },
    initSentXmlRequest() {
      Object.keys(this.promotionalRewards).forEach((key) => {
        this.promotionalRewards[key].sentXmlRequest = false;
      });
    },
  },
  mounted() {
    (this as any).resetWatcherActivated();
    if (this.insuranceData.priceFormData?.basic) {
      this.setValues(this.insuranceData.priceFormData[this.priceStep]);
      this.zusammenfassung.versicherungssummeAktuell = +getVersicherungssummeAktuell(this.insuranceData?.spreadsheetData?.biogasanlageItems);
      fillErgebnisseTarifkalkulationPramie(this.priceData, this.insuranceData?.spreadsheetData, this.ergebnisseTarifkalkulation);
    }

    this.initSentXmlRequest();
    (this as any).startWatcherActivated();
  },
  beforeDestroy() {
    if (this.isPCUser) return;
    this.onNextClick();
  },
});
